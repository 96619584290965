// logService.js
const aiserviceURL = localStorage.getItem("aiserviceURL");

const LOG_API_URL = aiserviceURL + "/logger/log"; // Replace with your actual API URL

/**
 * A global function to send log messages to the log API
 *
 * @param {string} logMessage - The log message to be sent.
 * @param {string} level - The level of the log (e.g., 'ERROR', 'INFO').
 * @param {string} userEmailId - The email ID of the user associated with the log.
 * @param {string} timestamp - The timestamp of when the log is created.
 * @param {string} serviceFunction - The function or service generating the log.
 */
export const logToApi = async (logMessage, level, userEmailId, timestamp, serviceFunction) => {
  const payload = {
    log_message: logMessage,
    level: level,
    user_email_id: userEmailId,
    timestamp: timestamp,
    service_function: serviceFunction,
  };

  try {
    const response = await fetch(LOG_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    console.log("Log uploaded successfully:", result);
  } catch (error) {
    console.error("Failed to log message:", error);
  }
};
